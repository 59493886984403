import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { logEvent, LogLevel } from '@praxis/component-logging'

const getHistoricalCorrections = async () => {
  let apiUrl = `${apiConfig.constraints.url}` + '/historicalcorrections' + '?key=' + `${apiConfig.constraints.key}`

  const { data } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)

  return data
}

export function useHistoricalCorrections(enabled = true) {
  const { isAuthenticated, login } = useAuth()
  const authenticated = isAuthenticated()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: ['historicalCorrections'],
    queryFn: () => getHistoricalCorrections(),
    enabled: authenticated && enabled,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 10, // 10 minutes
    onError: (error) => {
      const loggingInfo = {
        message: 'GET /historicalcorrections response has an error (useHistoricalCorrections).',
        url: window.location.href,
        error: {
          url: error?.config?.url,
          data: error?.response?.data,
          headers: error?.response?.headers,
          status: error?.response?.status,
          statusText: error?.response?.statusText,
        },
      }
      const optionalLoggingOptions = {
        level: LogLevel.Error,
      }
      logEvent(loggingInfo, optionalLoggingOptions)
    },
  })
}
