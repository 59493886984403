import {
  addMonths,
  eachDayOfInterval,
  format,
  getMinutes,
  isValid,
  isWithinInterval,
  setHours,
  setMinutes,
  startOfWeek,
  subDays,
  subWeeks,
} from 'date-fns'
import { queryClient } from '../../api/queryClient'
import {
  CATEGORY_FEATURE_MAP,
  CROSS_MIDNIGHT,
  DETAIL_OVERLAP,
  TIME_OFF_GROUPS,
  TIME_OFF_TYPES,
  requestRowVariants,
} from '../../constants/RequestConstants'
import {
  LOCATION_TYPE_DC,
  LOCATION_TYPE_OFFICE,
  LOCATION_TYPE_RGD,
  LOCATION_TYPE_STORE,
} from '../../constants/locationConstants'
import {
  doesCrossMidnight,
  doesOverlap,
  getDateOfTodayWithNoTimestamp,
  isDatePastScheduleGenerationCutoff,
  validateMinutes,
} from '../../utils/DateUtil'
import {
  ADD_DETAIL,
  CHANGE_PTO_VALUE,
  CHANGE_UNPAID_VALUE,
  CLEAR_PREVIOUS_STATE,
  CLEAR_REQUEST,
  INITIALIZE_REQUEST_CONFIG,
  POST_REQUESTS_DATA_ERROR,
  POST_REQUESTS_DATA_SUCCESS,
  REMOVE_DETAIL,
  SET_COMMENT,
  SET_COMMENT_ERROR,
  SET_DAY_ERROR,
  SET_REQUEST_HAS_ERRORS,
  SET_USE_PTO_DETAILS,
  TOGGLE_ALL_DAY_UNPAID,
  TOGGLE_USE_PTO,
  UPDATE_REQUEST_DATE_RANGE,
} from './actionType'

const defaultRowError = {
  minutes: '',
  startTime: '',
}

const futureInitialDetail = {
  usePTO: false,
  startTime: null,
  minutes: null,
  type: 'Unpaid',
  error: defaultRowError,
  ptoDetails: {
    minutes: null,
    type: '',
    error: defaultRowError,
  },
}

const futureInitialState = {
  config: {
    variant: requestRowVariants.TIME_OFF_UNPAID,
    allowUnpaid: true,
    allowPTOFill: true,
  },
  error: '',
  allDayUnpaid: false,
  requestDetails: [futureInitialDetail],
}

const scheduledInitialDetail = {
  startTime: null,
  minutes: null,
  type: '',
  error: defaultRowError,
}

const scheduledInitialState = {
  config: {
    variant: requestRowVariants.TIME_OFF_PTO,
    allowUnpaid: false,
    allowPTOFill: false,
  },
  error: '',
  requestDetails: [scheduledInitialDetail],
}

const initialRequestStateByVariant = {
  [requestRowVariants.TIME_OFF_UNPAID]: futureInitialState,
  [requestRowVariants.TIME_OFF_PTO]: scheduledInitialState,
}

const getRequestDayVariant = (date) => {
  const locationDetails = queryClient?.getQueryData(['locationDetails'])
  switch (locationDetails?.location_type) {
    case LOCATION_TYPE_STORE:
      return isDatePastScheduleGenerationCutoff(date)
        ? requestRowVariants.TIME_OFF_UNPAID
        : requestRowVariants.TIME_OFF_PTO
    case LOCATION_TYPE_OFFICE:
      return requestRowVariants.TIME_OFF_PTO
    default:
      return requestRowVariants.TIME_OFF_PTO
  }
}

const initialNewDayState = (date) => {
  return {
    ...initialRequestStateByVariant[getRequestDayVariant(date)],
    date,
  }
}

export const DEFAULT_REQUEST_CONFIG = {
  [TIME_OFF_GROUPS.TIME_OFF]: {
    [LOCATION_TYPE_STORE]: {
      header: {
        type: 'range',
        maxDate: addMonths(getDateOfTodayWithNoTimestamp(), 6),
        minDate: subDays(getDateOfTodayWithNoTimestamp(), 14),
      },
      content: {
        unpaidMinuteDropdownValues: [0, 15, 30, 45],
        unpaidHourDropdownValues: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
        ],
        paidMinuteDropdownValues: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
          30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
          57, 58, 59,
        ],
        paidHourDropdownValues: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        commentRequiredFor: [], //TIME_OFF_TYPES.PTO_VACATION, TIME_OFF_TYPES.PTO_OTHER, TIME_OFF_TYPES.UNPAID, TIME_OFF_TYPES.PTO_SICK
        commentAllowed: false,
        minUnpaidMinutes: 15,
        maxUnpaidMinutes: 24 * 60,
        minMinutes: 1,
        maxMinutes: 12 * 60,
        showDateHeader: true,
        startTimeMinutesStep: 1,
        unpaidStartTimeMinutesStep: 15,
        maxPaidPerDay: 12 * 60,
      },
    },
    [LOCATION_TYPE_DC]: {
      header: {
        type: 'range',
        maxDate: null,
        minDate: null,
      },
      comment: {},
      footer: {
        // any config for submit and cancel buttons at request level?
      },
    },
    [LOCATION_TYPE_OFFICE]: {
      header: {
        type: 'range',
        maxDate: addMonths(getDateOfTodayWithNoTimestamp(), 6),
        minDate: startOfWeek(subWeeks(getDateOfTodayWithNoTimestamp(), 13)),
      },
      content: {
        paidMinuteDropdownValues: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
          30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
          57, 58, 59,
        ],
        paidHourDropdownValues: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        commentRequiredFor: [], //TIME_OFF_TYPES.PTO_VACATION, TIME_OFF_TYPES.PTO_OTHER, TIME_OFF_TYPES.UNPAID, TIME_OFF_TYPES.PTO_SICK
        commentAllowed: false,
        minMinutes: 1,
        maxMinutes: 12 * 60,
        showDateHeader: true,
        startTimeMinutesStep: 1,
        maxPaidPerDay: 12 * 60,
      },
    },
    [LOCATION_TYPE_RGD]: {
      header: {
        type: 'range',
        maxDate: addMonths(getDateOfTodayWithNoTimestamp(), 6),
        minDate: startOfWeek(subWeeks(getDateOfTodayWithNoTimestamp(), 13)),
      },
      content: {
        paidMinuteDropdownValues: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
          30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
          57, 58, 59,
        ],
        paidHourDropdownValues: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        commentRequiredFor: [], //TIME_OFF_TYPES.PTO_VACATION, TIME_OFF_TYPES.PTO_OTHER, TIME_OFF_TYPES.UNPAID, TIME_OFF_TYPES.PTO_SICK
        commentAllowed: false,
        minMinutes: 1,
        maxMinutes: 12 * 60,
        showDateHeader: true,
        startTimeMinutesStep: 1,
        maxPaidPerDay: 12 * 60,
      },
    },
  },
}

const getRequestDropdownValues = (configResponseData) => {
  let dropdownValues = configResponseData?.configuration_values?.map((item) => item.primary_value) || []

  return dropdownValues
}

// remove all and the `|| !pilotGsclUnpaidLocations.includes(locationId)` on 228 for full rollout
const pilotGsclUnpaidLocations = [
  3858, 3863, 3866, 3873, 3840, 3841, 3842, 600, 3810, 3859, 3861, 3862, 3871, 3872, 3876, 3886, 9156, 9407, 9478, 9479,
]
const getVisibleDropdownValues = (locationType, locationId, dropdownValues) => {
  let visibleDropdownValues = dropdownValues
  if (locationType === LOCATION_TYPE_STORE || !pilotGsclUnpaidLocations.includes(locationId)) {
    let valueToRemove = TIME_OFF_TYPES.UNPAID
    if (visibleDropdownValues.includes(valueToRemove)) {
      visibleDropdownValues = dropdownValues.filter((item) => item !== valueToRemove)
    }
  }

  return visibleDropdownValues
}

const getRequestConfigForUser = (requestType, configResponseData) => {
  const locationDetails = queryClient?.getQueryData(['locationDetails'])
  const locationType = locationDetails?.location_type
  const locationId = locationDetails?.location_id

  const defaultRequestConfig = DEFAULT_REQUEST_CONFIG[requestType][locationType]
  const dropdownValues = getRequestDropdownValues(configResponseData)
  const visibleDropdownValues = getVisibleDropdownValues(locationType, locationId, dropdownValues)
  const visibleDropdownValuesSorted = visibleDropdownValues?.sort()

  return {
    ...defaultRequestConfig,
    requestType: requestType,
    content: {
      ...defaultRequestConfig.content,
      types: {
        possibleTypes: dropdownValues,
        selectableTypes: visibleDropdownValuesSorted,
      },
    },
  }
}

const initializeRequestConfig = (configFunctionality, configResponseData) => {
  const requestConfig = getRequestConfigForUser(CATEGORY_FEATURE_MAP.get(configFunctionality), configResponseData)
  return requestConfig
}

const initialState = {
  timeOffPostResponse: null,
  timeOffPostErrorResponse: null,
  startDate: null,
  endDate: null,
  comment: '',
  requestConfig: null,
  request: {},
  commentError: '',
  requestHasErrors: false,
}

export default function requestTimeOff(state = initialState, action = {}) {
  switch (action.type) {
    case POST_REQUESTS_DATA_SUCCESS: {
      return {
        ...state,
        timeOffPostResponse: action.data,
      }
    }
    case POST_REQUESTS_DATA_ERROR: {
      let error
      if (action.data.response) {
        error = action.data.response.data
      } else {
        error = action.data.message
      }
      return {
        ...state,
        timeOffPostErrorResponse: error,
      }
    }
    case CLEAR_PREVIOUS_STATE: {
      return {
        ...state,
        timeOffPostResponse: null,
        timeOffPostErrorResponse: null,
      }
    }
    // Time Off Unpaid + Paid
    case UPDATE_REQUEST_DATE_RANGE: {
      const start = action.start
      const end = action.end

      const locationDetails = queryClient?.getQueryData(['locationDetails'])

      if (!start || !end) {
        return { ...state, startDate: start, endDate: end }
      }

      const dateRange = eachDayOfInterval({ start, end })

      const newDates = dateRange.reduce((acc, date) => {
        const dateString = format(date, 'MM/dd/yyyy')
        if (!state.request[dateString]) {
          acc[dateString] = initialNewDayState(dateString, locationDetails?.location_type)
        }
        return acc
      }, {})

      const updatedRequestDetails = Object.keys(state.request).reduce((acc, date) => {
        if (isWithinInterval(new Date(date), { start, end })) {
          acc[date] = state.request[date]
        }
        return acc
      }, {})

      // merge the new dates and updated requests
      const mergedDates = { ...updatedRequestDetails, ...newDates }

      // sort the keys
      const sortedKeys = Object.keys(mergedDates).sort((a, b) => new Date(a) - new Date(b))

      // create a new sorted object
      const sortedDates = sortedKeys.reduce((acc, key) => {
        acc[key] = mergedDates[key]
        return acc
      }, {})

      return {
        ...state,
        startDate: start,
        endDate: end,
        request: sortedDates,
      }
    }
    case CHANGE_PTO_VALUE: {
      const contentConfig = state.requestConfig.content
      const dayToValidate = state.request[action.date]
      const detailToValidate = dayToValidate.requestDetails[action.index]
      let { detailError, dayError } = validateRequestDetailValueChange(
        contentConfig,
        dayToValidate,
        detailToValidate,
        action.field,
        action.value,
        action.index,
        requestRowVariants.TIME_OFF_PTO,
      )

      return {
        ...state,
        request: {
          ...state.request,
          [action.date]: {
            ...state.request[action.date],
            error: dayError,
            requestDetails: state.request[action.date].requestDetails.map((detail, index) =>
              index === action.index ? { ...detail, [action.field]: action.value, error: detailError } : detail,
            ),
          },
        },
      }
    }
    case CHANGE_UNPAID_VALUE: {
      const contentConfig = state.requestConfig.content
      const dayToValidate = state.request[action.date]
      const detailToValidate = dayToValidate.requestDetails[action.index]
      let { detailError, ptoDetails, dayError } = validateTimeOffUnpaidDetail(
        contentConfig,
        dayToValidate,
        detailToValidate,
        action.field,
        action.value,
        action.index,
        requestRowVariants.TIME_OFF_UNPAID,
      )
      return {
        ...state,
        request: {
          ...state.request,
          [action.date]: {
            ...state.request[action.date],
            error: dayError,
            requestDetails: state.request[action.date].requestDetails.map((detail, index) =>
              index === action.index
                ? { ...detail, [action.field]: action.value, error: detailError, ptoDetails: ptoDetails }
                : detail,
            ),
          },
        },
      }
    }
    case ADD_DETAIL: {
      const variant = state.request[action.date].config.variant
      return {
        ...state,
        request: {
          ...state.request,
          [action.date]: {
            ...state.request[action.date],
            error: '',
            requestDetails: [
              ...state.request[action.date].requestDetails,
              ...initialRequestStateByVariant[variant].requestDetails,
            ],
          },
        },
      }
    }
    case REMOVE_DETAIL: {
      return {
        ...state,
        request: {
          ...state.request,
          [action.date]: {
            ...state.request[action.date],
            error: '',
            requestDetails: state.request[action.date].requestDetails.filter((_, index) => index !== action.index),
          },
        },
      }
    }
    case TOGGLE_ALL_DAY_UNPAID: {
      let midnightDate = setHours(setMinutes(new Date(action.date), 0), 0)
      let detail = { ...state.request[action.date].requestDetails[0] }
      return {
        ...state,
        request: {
          ...state.request,
          [action.date]: {
            ...state.request[action.date],
            error: '',
            allDayUnpaid: !state.request[action.date].allDayUnpaid,
            requestDetails: [
              {
                ...detail,
                minutes: 24 * 60,
                startTime: midnightDate,
                error: defaultRowError,
                ptoDetails: {
                  ...detail.ptoDetails,
                  error: defaultRowError,
                },
              },
            ],
          },
        },
      }
    }
    case TOGGLE_USE_PTO: {
      return {
        ...state,
        request: {
          ...state.request,
          [action.date]: {
            ...state.request[action.date],
            error: '',
            requestDetails: state.request[action.date].requestDetails.map((detail, index) =>
              index === action.index ? { ...detail, usePTO: !detail.usePTO } : detail,
            ),
          },
        },
      }
    }
    case SET_USE_PTO_DETAILS: {
      const { date, index, field, value } = action
      const maxMinutes = state.requestConfig.content.maxMinutes
      const detailToValidate = state.request[action.date].requestDetails[action.index]
      let { ptoDetailMinutesError } = validateUnpaidPtoDetails(detailToValidate, field, value, maxMinutes)
      return {
        ...state,
        request: {
          ...state.request,
          [date]: {
            ...state.request[date],
            error: '',
            requestDetails: state.request[date].requestDetails.map((detail, idx) =>
              idx === index
                ? {
                    ...detail,
                    ptoDetails: {
                      ...detail.ptoDetails,
                      [field]: value,
                      error: {
                        ...detail.error,
                        minutes: ptoDetailMinutesError,
                      },
                    },
                  }
                : detail,
            ),
          },
        },
      }
    }
    case SET_COMMENT: {
      return {
        ...state,
        comment: action.comment,
        commentError: '',
      }
    }
    case INITIALIZE_REQUEST_CONFIG: {
      const { configFunctionality, configResponseData } = action
      return {
        ...state,
        requestConfig: initializeRequestConfig(configFunctionality, configResponseData),
      }
    }
    case SET_DAY_ERROR: {
      const { date, error } = action
      return {
        ...state,
        request: {
          ...state.request,
          [date]: {
            ...state.request[date],
            error,
          },
        },
      }
    }
    case SET_COMMENT_ERROR: {
      const { error } = action
      return {
        ...state,
        commentError: error,
      }
    }
    case SET_REQUEST_HAS_ERRORS: {
      let { hasErrors } = action
      return {
        ...state,
        requestHasErrors: hasErrors,
      }
    }
    case CLEAR_REQUEST: {
      return initialState
    }
    default:
      return state
  }
}

// requestRowVariants.TIME_OFF_UNPAID validation
const validateTimeOffUnpaidDetail = (contentConfig, dayToValidate, detailToValidate, field, value, index, variant) => {
  let { detailError, dayError } = validateRequestDetailValueChange(
    contentConfig,
    dayToValidate,
    detailToValidate,
    field,
    value,
    index,
    variant,
  )

  let ptoDetailMinutesError = detailToValidate.ptoDetails.error.minutes
  if (detailToValidate.usePTO && field === 'minutes') {
    ptoDetailMinutesError = validateMinutes(detailToValidate.ptoDetails.minutes, 0, value)
  }

  return {
    detailError,
    dayError,
    ptoDetails: { ...detailToValidate.ptoDetails, error: { minutes: ptoDetailMinutesError } },
  }
}

const validateRequestDetailValueChange = (
  contentConfig,
  dayToValidate,
  detailToValidate,
  field,
  value,
  index,
  variant,
) => {
  const startTime = field === 'startTime' ? value : detailToValidate.startTime
  const minutes = field === 'minutes' ? value : detailToValidate.minutes
  // const requestType = field === 'type' ? value : detailToValidate.type
  let detailError = { ...defaultRowError }
  let dayError = ''

  const minMinutes =
    variant === requestRowVariants.TIME_OFF_UNPAID ? contentConfig.minUnpaidMinutes : contentConfig.minMinutes
  const maxMinutes =
    variant === requestRowVariants.TIME_OFF_UNPAID ? contentConfig.maxUnpaidMinutes : contentConfig.maxMinutes
  const minutesStep =
    variant === requestRowVariants.TIME_OFF_UNPAID
      ? contentConfig.unpaidStartTimeMinutesStep
      : contentConfig.startTimeMinutesStep

  // ensure start time is valid step even when typing.
  if (startTime && isValid(startTime)) {
    let minutesSelected = getMinutes(startTime)
    if (minutesSelected % minutesStep !== 0) {
      detailError.startTime = 'Invalid time'
    }
  }

  if (minutes !== null) {
    detailError.minutes = validateMinutes(minutes, minMinutes, maxMinutes)
  }

  if (startTime && minutes !== null && !detailError.minutes) {
    detailError.minutes = doesCrossMidnight(startTime, minutes) ? CROSS_MIDNIGHT : ''
    // if multiple details in a day
    if (dayToValidate.requestDetails.length > 1) {
      let otherIndex = index === 0 ? 1 : 0
      let startDateToCompare = dayToValidate.requestDetails[otherIndex]?.startTime
      let durationToCompare = dayToValidate.requestDetails[otherIndex]?.minutes

      // if both minutes and start time are filled in
      if (startDateToCompare && durationToCompare) {
        dayError = doesOverlap(startTime, minutes, startDateToCompare, durationToCompare) ? DETAIL_OVERLAP : ''
      }
    }
  }

  return { detailError, dayError }
}

const validateUnpaidPtoDetails = (detailToValidate, field, value, maxMinutes) => {
  const minutes = field === 'minutes' ? value : detailToValidate.ptoDetails.minutes
  const max = maxMinutes > detailToValidate.minutes ? detailToValidate.minutes : maxMinutes
  let ptoDetailMinutesError = validateMinutes(minutes, 0, max)

  return { ptoDetailMinutesError }
}
