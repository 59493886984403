import { CircularProgress, Paper, Typography, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useUser } from '../../../auth/hooks/useUser'
import { isDateBeforeCurrentWeek, isDateBeforeNumberOfWeeksFromToday } from '../../../utils/DateUtil'
import { LOCATION_TYPE_DC, LOCATION_TYPE_RGD, LOCATION_TYPE_STORE } from '../../../constants/locationConstants'
import { useHistoricalCorrections } from '../../../api/constraints/useHistoricalCorrections'
import { useInfiniteRequests } from '../../../api/requests/useRequests'
import { REQUEST_TYPE_PUNCH_CORRECTION } from '../../../constants/RequestConstants'
import { MAX_WEEKS_ALLOWED_BACKWARDS_FOR_STORES } from '../../../constants/TimecardConstants'
import FixAPunchButton from './FixAPunchButton'

const FIX_A_PUNCH_LABEL = 'FIX A PUNCH'
const FIX_A_PUNCH_UNAVAILABLE_MESSAGE =
  'Punch corrections are unavailable. Please check back on Tuesday after the weekly payroll process has completed.'
const PENDING_PUNCH_CORRECTION_MESSAGE =
  'You have a pending punch correction for this date. Your timecard will be updated after your leader approves your request.'
const CHANGES_TO_TIME_CARD_MESSAGE = 'Changes to your timecard may take up to 3 minutes to reflect on this screen.'
const SUPPRESSION_ON = 'ON'
const SUPPRESSION_OFF = 'OFF'

const FixAPunchButtonSection = ({ timeCardDataDaily }) => {
  const theme = useTheme()
  const user = useUser()
  const [isHcSuppression, setIsHcSuppression] = useState()
  const [isBeforeMaxWeeks, setIsBeforeMaxWeeks] = useState()
  const [havePendingPC, setHavePendingPC] = useState()
  const [isWithinTimeOfRecentSubmission, setIsWithinTimeOfRecentSubmission] = useState()
  const tzCode = user.locationData.iso_time_zone_code
  const timeCardDailySelectedDate = useSelector((state) => state.timecardDaily.dailySelectedDate)
  const isBeforeCurrentWeek = isDateBeforeCurrentWeek(timeCardDailySelectedDate, tzCode)
  const isStoreType = user.locationData.location_type === LOCATION_TYPE_STORE
  const { data: hcData, isFetching: hcIsFetching, error: hcError } = useHistoricalCorrections(isBeforeCurrentWeek)
  const {
    data: pcData,
    isFetching: pcIsFetching,
    error: pcError,
  } = useInfiniteRequests('All', REQUEST_TYPE_PUNCH_CORRECTION, isStoreType && !isHcSuppression)

  useEffect(() => {
    if (isBeforeCurrentWeek) {
      if (hcData && hcData.HISTORICAL_CORRECTIONS_SUPPRESSION === SUPPRESSION_ON) {
        setIsHcSuppression(true)
      } else if (hcData && hcData.HISTORICAL_CORRECTIONS_SUPPRESSION === SUPPRESSION_OFF) {
        setIsHcSuppression(false)
      }
    } else {
      setIsHcSuppression(false)
    }
  }, [hcData, isBeforeCurrentWeek, timeCardDataDaily.date])

  useEffect(() => {
    if (isStoreType) {
      if (pcData) {
        if (
          Array.isArray(pcData.pages) &&
          pcData.pages.length > 0 &&
          pcData.pages[0].requests.some(
            (item) => item.status.toLowerCase() === 'pending' && item.punch_date === timeCardDataDaily.date,
          )
        ) {
          setHavePendingPC(true)
        } else {
          setHavePendingPC(false)
        }
      }
    } else {
      setHavePendingPC(false)
    }
  }, [isStoreType, pcData, timeCardDataDaily.date])

  useEffect(() => {
    if (
      isStoreType &&
      isDateBeforeNumberOfWeeksFromToday(timeCardDailySelectedDate, MAX_WEEKS_ALLOWED_BACKWARDS_FOR_STORES, tzCode)
    ) {
      setIsBeforeMaxWeeks(true)
    } else {
      setIsBeforeMaxWeeks(false)
    }
  }, [tzCode, timeCardDataDaily.date, timeCardDailySelectedDate, isStoreType])

  useEffect(() => {
    let arr = JSON.parse(localStorage.getItem('latestPCDates') || '[]')
    const index = arr.findIndex((item) => item.date === timeCardDataDaily.date)
    const isTimeExceeded = index > -1 && new Date().getTime() - new Date(arr[index].lastUpdated).getTime() >= 180000
    const isLocationTypeValid = [LOCATION_TYPE_DC, LOCATION_TYPE_RGD].includes(user.locationData.location_type)

    if (isLocationTypeValid && index > -1) {
      if (isTimeExceeded) {
        arr.splice(index, 1)
        localStorage.setItem('latestPCDates', JSON.stringify(arr))
        setIsWithinTimeOfRecentSubmission(false)
      } else {
        setIsWithinTimeOfRecentSubmission(true)
      }
    } else {
      setIsWithinTimeOfRecentSubmission(false)
    }
  }, [timeCardDataDaily, user.locationData.location_type])

  const renderMessages = () => {
    return (
      <>
        {isHcSuppression && (
          <Typography
            variant="body2"
            tabIndex={0}
            sx={{ ...theme.infoMessages, padding: '8px', marginTop: 0 }}
            data-cy="fixAPunchSuppressionMessage"
          >
            {FIX_A_PUNCH_UNAVAILABLE_MESSAGE}
          </Typography>
        )}
        {havePendingPC && (
          <Typography variant="body2" tabIndex={0} sx={{ ...theme.infoMessages, padding: '8px', marginTop: 0 }}>
            {PENDING_PUNCH_CORRECTION_MESSAGE}
          </Typography>
        )}
        {isWithinTimeOfRecentSubmission && (
          <Typography variant="body2" tabIndex={0} sx={{ ...theme.infoMessages, padding: '8px', marginTop: 0 }}>
            {CHANGES_TO_TIME_CARD_MESSAGE}
          </Typography>
        )}
      </>
    )
  }

  const renderButton = () => {
    return (
      <Paper sx={theme.mainScrollContainer} elevation={1} square>
        {renderMessages()}
        <FixAPunchButton
          disabled={isHcSuppression || havePendingPC || isBeforeMaxWeeks || isWithinTimeOfRecentSubmission}
        />
      </Paper>
    )
  }

  const renderSavingSpinner = () => {
    return (
      <div style={{ padding: '10px 40px 10px 40px', textAlign: 'right' }}>
        <CircularProgress size={32} />
      </div>
    )
  }
  const renderErrorMessage = () => {
    return (
      <div style={{ padding: '10px 40px 10px 40px', textAlign: 'center', color: theme.errorMessages.color }}>
        <Typography>Error loading '{FIX_A_PUNCH_LABEL}' button</Typography>
      </div>
    )
  }

  if (hcIsFetching || pcIsFetching) return renderSavingSpinner()
  if (hcError || pcError) return renderErrorMessage()
  if (
    ![isHcSuppression, havePendingPC, isBeforeMaxWeeks, isWithinTimeOfRecentSubmission].some((i) => i === undefined)
  ) {
    return renderButton()
  }
}
export default FixAPunchButtonSection

export const FixAPunchButtonSectionNotPunchedIn = () => {
  const theme = useTheme()
  return (
    <section tabIndex={0}>
      <Typography
        data-cy="fixAPunchNotPunchedIn"
        variant="body2"
        sx={{ ...theme.infoMessages, padding: '8px', marginTop: 0 }}
      >
        You must be on the clock to fix a punch. If you need to fix a punch, please clock in to enable the Fix a Punch
        button.
      </Typography>
      <Typography variant="body2" sx={{ ...theme.infoMessages, padding: '8px', marginTop: 0 }}>
        Note: There may be up to a 3-minute delay after clocking in for the Fix a Punch button to be enabled.
      </Typography>
      <FixAPunchButton disabled={true} />
    </section>
  )
}
