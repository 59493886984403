import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { setBackToComponent } from '../../../store/header/actionCreator'
import { Button } from '@mui/material'
import FixAPunchAcceptGoBack from './FixAPunchAcceptGoBack'

const DAILY_TIME_CARD_PAGE_PATH = '/team-member/timecardDaily'
const WEEKLY_TIME_CARD_PAGE_PATH = '/team-member/timecardWeekly'
const FIX_A_PUNCH_LABEL = 'FIX A PUNCH'

export const FixAPunchButton = ({ disabled }) => {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const [fixAPunchDialogOpen, setFixAPunchDialogOpen] = useState(false)

  const fixAPunchButtonClicked = () => {
    dispatch(setBackToComponent(DAILY_TIME_CARD_PAGE_PATH))
    setFixAPunchDialogOpen(true)
  }

  const handleGoBackAction = () => {
    dispatch(setBackToComponent(WEEKLY_TIME_CARD_PAGE_PATH))
    setFixAPunchDialogOpen(false)
  }

  const handleAcceptAction = () => {
    navigate('/team-member/timecardDaily/fixapunch')
  }

  return (
    <React.Fragment>
      <div style={{ textAlign: 'right' }}>
        <Button
          data-cy="fixAPunchBtn"
          sx={{ margin: '10px' }}
          color="primary"
          variant="contained"
          onClick={() => {
            fixAPunchButtonClicked()
          }}
          disabled={disabled}
          aria-disabled={disabled}
          tabIndex={0}
        >
          {FIX_A_PUNCH_LABEL}
        </Button>
      </div>
      <FixAPunchAcceptGoBack
        showFixAPunchDialog={fixAPunchDialogOpen}
        handleGoBackAction={handleGoBackAction}
        handleAcceptAction={handleAcceptAction}
      />
    </React.Fragment>
  )
}
export default FixAPunchButton
