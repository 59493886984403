import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import React from 'react'
import { useAccruals } from '../../api/accruals/useAccruals'
import { IError } from '../../api/globalResponses'
import { processError } from '../../utils/api-errors'
import InnerLoader from '../Loader/InnerLoader'
interface AccrualInfoProps {
  workerId: string
  locationId: string
}

const AccrualInfo: React.FC<AccrualInfoProps> = ({ workerId, locationId }) => {
  const {
    adjustedVacation,
    adjustedSick,
    plannedVacation,
    plannedSick,
    totalAvailableVacation,
    totalAvailableSick,
    asOfDate,
    error,
    data,
  } = useAccruals(workerId, locationId)

  if (data) {
    return (
      <>
        <Accordion data-cy={`accrualAccordion`} disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color={'primary'} />}
            aria-controls="panel1c-content"
            id="panel1c-header"
            sx={{ paddingX: 1 }}
          >
            <Grid container paddingLeft={1} justifyContent={'space-between'}>
              <Grid container item direction="column">
                <Grid item container marginBottom={1}>
                  <Typography role="heading" aria-label={'Available Balance'} fontWeight={'bolder'}>
                    Available Balance:&nbsp;
                  </Typography>
                </Grid>
                {AccrualSummary('Vacation Available:', totalAvailableVacation)}
                {AccrualSummary('Sick Available:', totalAvailableSick)}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {AccrualDetail(
              'Vacation Balance',
              'Current balance as of',
              adjustedVacation,
              'Pending balance as of now:',
              plannedVacation,
              'Total Available Balance:',
              totalAvailableVacation,
              asOfDate,
            )}
            {AccrualDetail(
              'Sick Balance',
              'Current balance as of',
              adjustedSick,
              'Pending balance as of now:',
              plannedSick,
              'Total Available Balance:',
              totalAvailableSick,
              asOfDate,
            )}
            {AccrualDetailExplained()}
          </AccordionDetails>
        </Accordion>
      </>
    )
  }

  if (error) {
    let accrualError = processError((error as AxiosError<IError>).response?.data) // can default all our errors to IError in react query in next react query version.

    return (
      <Grid container padding={1}>
        <Typography color={'primary'}>{accrualError.message}</Typography>
      </Grid>
    )
  }

  return (
    <Grid container padding={1}>
      <InnerLoader size={24} />
    </Grid>
  )
}

const AccrualSummary = (field: string, value: any) => {
  return (
    <>
      <Grid item container marginLeft={1}>
        <Typography role="heading" aria-label={field} variant="body2">
          {field}&nbsp;
        </Typography>
        <Typography
          role="heading"
          aria-label={value}
          sx={{ display: 'block' }}
          component="span"
          variant="body2"
          color={getRedColorForHyphen(value, 'secondary.dark')}
        >
          {value}&nbsp;
        </Typography>
      </Grid>
    </>
  )
}

const AccrualDetail = (
  title: string,
  field1: string,
  value1: any,
  field2: string,
  value2: any,
  total: string,
  totalValue: any,
  asOfDate: any,
) => {
  return (
    <Grid container item marginLeft={1} marginBottom={2}>
      <Typography fontWeight={'medium'}>
        <u>{title}</u>
      </Typography>
      <Grid container>
        <Grid item xs={8}>
          <Typography role="heading" aria-label={field1} variant="body2">
            {field1}&nbsp;
            {asOfDate}:
          </Typography>
        </Grid>
        <Grid item xs={4} paddingRight={2} textAlign={'right'}>
          <Typography role="heading" aria-label={'value'} variant="body2">
            &nbsp;&nbsp;&nbsp;{value1}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography role="heading" aria-label={field2} variant="body2">
            {field2}
          </Typography>
        </Grid>
        <Grid item xs={4} paddingRight={2} textAlign={'right'}>
          <Typography role="heading" aria-label={'value'} variant="body2">
            <b>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
            {value2}
          </Typography>
        </Grid>
        <Grid item xs={12} paddingRight={2} textAlign={'right'}>
          <hr />
        </Grid>
        <Grid item xs={8}>
          <Typography role="heading" aria-label={'total'} variant="body2">
            {total}
          </Typography>
        </Grid>
        <Grid item xs={4} paddingRight={2} textAlign={'right'}>
          <Typography fontWeight={'medium'} color={getRedColorForHyphen(totalValue, 'secondary.dark')}>
            &nbsp;&nbsp;&nbsp;&nbsp;{totalValue}{' '}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const AccrualDetailExplained = () => {
  return (
    <Grid container>
      <Grid item margin={1} xs={12}>
        <Typography
          role="heading"
          aria-label={'Current Balance Explained'}
          variant="body2"
          color="tertiary.contrastText"
          fontStyle={'italic'}
        >
          *Current Balance is your Workday balance minus any processed time off requests not yet accounted for in your
          Workday balance. Workday balances update weekly on Tuesday mornings in myTime for Target.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AccrualInfo

const getRedColorForHyphen = (value: any, defaultColor: any) => {
  return value && value.charAt(0) === '-' ? '#cc0000' : defaultColor
}
