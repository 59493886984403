import { Divider, Grid, Link, Popover, Typography } from '@mui/material'

import { useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

export default AvailableShiftsEligibilityPopover

function AvailableShiftsEligibilityPopover() {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const popoverId = open ? 'help-popover' : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Grid container alignItems="center">
        <Divider />
        <Grid item>
          <Typography
            variant="body2"
            component="h1"
            sx={{
              fontWeight: 'bold',
              fontSize: '14px',
              paddingTop: '2px',
            }}
          >
            See
            <Link onClick={handleClick} sx={{ cursor: 'pointer' }}>
              {' '}
              eligibility rules{' '}
            </Link>
            for available shifts
          </Typography>
        </Grid>
      </Grid>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopOverContent />
      </Popover>
    </div>
  )
}

function PopOverContent() {
  return (
    <Grid container flexDirection={'column'} gap={2} padding={2}>
      <Grid container item justifyContent={'center'}>
        <Typography>
          You can only see available shifts on this screen that you are eligible to cover. The following determines
          eligibility:
        </Typography>
      </Grid>
      <Grid container item justifyContent={'center'}>
        <List>
          <ListItem>
            <Typography>
              1) The available shift must not make your total hours in a pay period greater than 40 hours.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>2) The available shift must be in a job that you are eligible to work in.</Typography>
          </ListItem>
          <ListItem>
            <Typography>
              3) The available shift must not have an overlapping time period of one of your existing shifts.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              4) Visibility to open/available shifts is based on your primary and secondary work centers keyed into
              myTime.
            </Typography>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
}
