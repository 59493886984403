import { useAuth } from '@praxis/component-auth'
import { useInfiniteQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import {
  REQUEST_TYPE_AVAILABILITY,
  REQUEST_TYPE_MASS_VACATION,
  REQUEST_TYPE_PUNCH_CORRECTION,
  REQUEST_TYPE_TIME_OFF,
} from '../../constants/RequestConstants'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import {
  ERROR_CODES_SHOW_MESSAGE,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
  UNABLE_TO_LOAD,
} from '../../constants/errorConstants'
import { showNotificationError } from '../../store/notification/actionCreator'
import { massVacationManager } from '../../utils/DateUtil'
import { formatErrorCode } from '../../utils/ErrorHandling'

const PER_PAGE = 15

const getRequests = async (requestType, workerId, locationId, status, pageNumber) => {
  let apiUrl = ''
  if (requestType === REQUEST_TYPE_TIME_OFF) {
    apiUrl =
      `${apiConfig.timeOff.url}` +
      '/time_off_requests' +
      '?key=' +
      `${apiConfig.worker.key}` +
      '&worker_id=' +
      workerId +
      '&location_id=' +
      locationId +
      '&status_name=' +
      status +
      '&page=' +
      pageNumber +
      '&per_page=' +
      PER_PAGE

    let { data, headers } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)
    data['x-total-count'] = headers['x-total-count']
    return data
  } else if (requestType === REQUEST_TYPE_AVAILABILITY) {
    apiUrl =
      `${apiConfig.storeAvailability.url}` +
      '/team_member_availabilities' +
      '?key=' +
      `${apiConfig.worker.key}` +
      '&worker_id=' +
      workerId +
      '&location_id=' +
      locationId +
      '&status_name=' +
      status +
      '&page=' +
      pageNumber +
      '&per_page=' +
      PER_PAGE

    let { data, headers } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)
    //temporary code for availability
    data['x-total-count'] = headers['x-total-count'] || data.total_availabilities
    data['status_name'] = status
    data['page'] = pageNumber
    data['per_page'] = PER_PAGE
    return data
  } else if (requestType === REQUEST_TYPE_PUNCH_CORRECTION) {
    apiUrl =
      `${apiConfig.punchCorrection.url}` +
      '/punch_correction_requests' +
      '?key=' +
      `${apiConfig.worker.key}` +
      '&worker_id=' +
      workerId +
      '&location_id=' +
      locationId +
      '&status_name=' +
      status +
      '&page=' +
      pageNumber +
      '&per_page=' +
      PER_PAGE

    let { data, headers } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)
    data['x-total-count'] = headers['x-total-count']
    return data
  } else if (requestType === REQUEST_TYPE_MASS_VACATION) {
    apiUrl =
      `${apiConfig.timeOff.url}` +
      '/mass_vacation_requests' +
      '?key=' +
      `${apiConfig.worker.key}` +
      '&worker_id=' +
      workerId +
      '&page=' +
      pageNumber +
      '&per_page=' +
      PER_PAGE

    // headers
    let { data, headers } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)
    data['x-total-count'] = headers['x-total-count'] || data.total_requests
    return data
  }
}

/**
 *
 * @param {*} status 'ALL' || 'Pending' ... etc
 * @param {*} overrideRequestType punch_correction || time_off || availability
 * @param enabled
 * @returns {useInfiniteQuery<TData, TError>}
 */
export function useInfiniteRequests(status, overrideRequestType, enabled = true) {
  const { isAuthenticated, login } = useAuth()
  const { userData, locationData } = useUser()
  const dispatch = useDispatch()
  let requestType = useSelector((state) => state.requests.requestType)
  let mvManager = massVacationManager()
  // allow for caller to override what api endpoint is used
  requestType = overrideRequestType ? overrideRequestType : requestType

  if (requestType === REQUEST_TYPE_MASS_VACATION && mvManager.hideForTM) {
    enabled = false
  }

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  return useInfiniteQuery(
    ['requests', { status, requestType }], // query key: this is what identifies the query. changing this will add a new key with its own cached data.
    ({ pageParam = 1 }) => getRequests(requestType, userData.worker_id, locationData.location_id, status, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1
        return allPages.length * PER_PAGE < lastPage['x-total-count'] ? nextPage : undefined
      },
      onError: (error) => {
        if (ERROR_CODES_SHOW_MESSAGE.includes(error?.response?.data.code)) {
          dispatch(showNotificationError(true, error?.response?.data.message))
        } else {
          dispatch(
            showNotificationError(
              true,
              UNABLE_TO_LOAD + ' ' + formatErrorCode(error?.response?.data, ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
            ),
          )
        }
      },
      enabled: isAuthenticated() && !!requestType && enabled,
    },
  )
}
