import { CircularProgress, Paper, useTheme } from '@mui/material'
import { startOfWeek, subWeeks } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTimeCardDaily } from '../../api/timecard/useTimeCardDaily'
import MyProtectedElement from '../../auth/MyProtectedElement'
import { useUser } from '../../auth/hooks/useUser'
import { MAX_WEEKS_ALLOWED_BACKWARDS } from '../../constants/TimecardConstants'
import {
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER_TIMECARD,
  ERROR_CODES_SHOW_MESSAGE_TIMECARD,
  NO_TIMECARD_MESSAGE,
  UNABLE_TO_LOAD_TIMECARDS,
} from '../../constants/errorConstants'
import { setBackToComponent } from '../../store/header/actionCreator'
import {
  handleDailyScheduleGetData,
  setTimecardDailySelectedDate,
  setTotalScheduledHrsDailyLoading,
} from '../../store/timecardDaily/actionCreator'
import { getDateOfTodayWithNoTimestamp, getNowDateInTimezone } from '../../utils/DateUtil'
import { formatErrorCode } from '../../utils/ErrorHandling'
import WeeklyCalendar from '../Calendar/WeeklyCalendar'
import HeaderTitle from '../Header/HeaderTitle'
import HoursSummaryDaily from './HoursSummaryDaily'
import OtherDaily from './OtherDaily'
import PunchesDaily from './PunchesDaily'
import FixAPunchButtonSection, { FixAPunchButtonSectionNotPunchedIn } from './PunchCorrection/FixAPunchButtonSection'

const PAGE_TITLE = 'My Time Card Details'
const WEEKLY_TIME_CARD_PAGE_PATH = '/team-member/timecardWeekly'

const getStyles = (theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  infoMessage: theme.infoMessages,
  infoMessageDetail: theme.infoMessageDetail,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  loadingIconContainer: theme.loadingIconContainer,
  highlights: {
    fontWeight: 'bold',
    fontSize: '100%',
    paddingTop: '2px',
  },
  highlightsContainer: {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
  },
  highlightsContainerPosition: {
    width: '100%',
  },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingTop: '0',
    paddingBottom: '0',
  },
  displaySegmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
  },
  startTimeItem: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
  },
  endTimeItem: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: '1',
    minHeight: '32px',
    paddingBottom: '.25em',
  },
  jobContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  jobContainerAtPopup: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
    paddingTop: '4px',
  },
  jobName: {
    paddingTop: '3px',
  },
  iconMargin: {
    marginRight: '.25em',
  },
  indicator: {
    marginTop: '-2px',
    marginLeft: '-15px',
    marginRight: '5px',
    fontSize: '24px',
    color: 'black',
    zIndex: '1',
  },
  jobNameContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '32px',
  },
  paycodeEditsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
  },
  paycodeEdits: {
    fontWeight: '300',
  },
  dateHeading: {
    fontWeight: 'bold',
    fontSize: 'medium',
  },
  lastEdit: {
    color: theme.palette.secondary.light,
  },
  dividerBox: {
    flexGrow: '1',
  },
  dividerStyle: {
    borderLeft: `1px solid ${theme.palette.tertiary.light}`,
    height: '93px',
    position: 'absolute',
    left: '48.5%',
    marginLeft: '0',
    marginTop: '-46px',
  },
  expandCollapseButtonsContainer: {
    margin: '0 auto',
    minHeight: '56px',
    maxWidth: '640px',
    width: '100%',
    padding: '0px',
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    alignItems: 'center',
  },
  arrowBtn: {
    paddingRight: '16px',
  },
  coverShiftBtnGrid: {
    display: 'flex',
    flexFlow: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  accordionElement: {
    paddingBottom: '1px',
  },
  loaderStyles: {
    fontSize: 16,
    display: 'flex',
  },
  innerLoaderLabelStyles: {
    paddingLeft: '6px',
    fontColor: '#cc0000',
  },
  individualShift: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  mobileButton: {
    maxWidth: '120px',
  },
  addtionalJobsContainer: {
    paddingTop: '1em',
  },
  row: {
    margin: 0,
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  col: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
  },
  colStart: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  colCenter: {
    flexGrow: '1',
    maxWidth: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
  },
  colEnd: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    textAlign: 'right',
  },
  colEndTextLeft: {
    flexGrow: '1',
    flexBasis: '0',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  redText: {
    color: '#CC0000',
  },
  boldText: {
    fontWeight: 'bold',
  },
  rightAlign: {
    textAlign: 'right',
  },
  sectionHeader1: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),
  },
  sectionHeader2: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),
  },
  segmentStyles1: {
    color: '#666',
  },
  segmentStyles2: {
    color: '#666',
    textAlign: 'center',
  },
  paddingTop3: {
    paddingTop: '3px',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '6px 0',
  },
  paddedContainer: {
    padding: '10px 10px',
  },
  totalHrsBorder: {
    width: '97.8%',
    padding: '5px',
    borderBottom: '1px dashed #999',
    marginBottom: '5px',
  },
  scheduleItemContainer: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
    fontSize: 'medium',
  },
  gridItemContainer: {
    flexGrow: '1',
    paddingTop: '.5em',
    minHeight: '32px',
  },
  missedPunchAdjHrsStatus: {
    marginTop: '3px',
  },
  headerStyles: {
    backgroundColor: '#eeeeee',
  },
  emptyDiv: {
    height: '40px',
    width: '100%',
    clear: 'both',
  },
  info: {
    textAlign: 'left',
    color: '#CC0000',
    padding: '10px',
  },
})

const findMissedPunch = (timecards) => {
  if (timecards) {
    for (let timecard of timecards) {
      if (timecard.has_missed_punch) {
        return true
      }
    }
  }
}

const TimecardDaily = () => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const user = useUser()

  const minDate = startOfWeek(subWeeks(getDateOfTodayWithNoTimestamp(), MAX_WEEKS_ALLOWED_BACKWARDS - 1))
  const maxDate = getDateOfTodayWithNoTimestamp()

  const totalScheduledHrsDaily = useSelector((state) => state.timecardDaily.totalScheduledHrs)
  const totalScheduledHrsDailyError = useSelector((state) => state.timecardDaily.totalScheduledHrsError)
  const totalScheduledHrsDailyLoading = useSelector((state) => state.timecardDaily.totalScheduledHrsLoading)

  const timeCardDailySelectedDate = useSelector((state) => state.timecardDaily.dailySelectedDate)
  const [totalScheduledHrsLoader, setTotalScheduledHrsLoader] = useState(true)
  const dispatch = useDispatch()

  const {
    data: timeCardDataDaily,
    error: timeCardDataDailyError,
    isFetching: timeCardDataDailyIsFetching,
  } = useTimeCardDaily({
    selectedDate: timeCardDailySelectedDate || getNowDateInTimezone(user.locationData.iso_time_zone_code),
  })

  const missedPunch = findMissedPunch(timeCardDataDaily?.timecards)
  const isPunchedIn = timeCardDataDaily?.timecards.some((timecard) => timecard.has_punched_in)

  const getTimecardDailyData = useCallback(
    (date) => {
      dispatch(setTotalScheduledHrsDailyLoading('Y'))
      dispatch(setTimecardDailySelectedDate(date))

      dispatch(handleDailyScheduleGetData(user.userData.worker_id, date, user.locationData.location_id))
    },
    [dispatch, user.userData.worker_id, user.locationData.location_id],
  )

  useEffect(() => {
    dispatch(setBackToComponent(WEEKLY_TIME_CARD_PAGE_PATH))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { iso_time_zone_code } = user.locationData
    let currentSelectedDate = timeCardDailySelectedDate
    if (iso_time_zone_code && !timeCardDailySelectedDate) {
      currentSelectedDate = getNowDateInTimezone(iso_time_zone_code)
    }
    if (currentSelectedDate) {
      dispatch(setTimecardDailySelectedDate(currentSelectedDate))
    }

    if (user.locationData.location_id) {
      getTimecardDailyData(currentSelectedDate)
    }
  }, [
    user.locationData.iso_time_zone_code,
    user.locationData,
    dispatch,
    getTimecardDailyData,
    timeCardDailySelectedDate,
  ])

  useEffect(() => {
    if (totalScheduledHrsDailyLoading === 'N' && totalScheduledHrsDaily !== null) {
      setTotalScheduledHrsLoader(false)
    } else if (totalScheduledHrsDailyLoading === 'Y') {
      setTotalScheduledHrsLoader(true)
    } else if (totalScheduledHrsDailyLoading === 'N' && totalScheduledHrsDailyError !== null) {
      setTotalScheduledHrsLoader(false)
    }
  }, [totalScheduledHrsDailyLoading, totalScheduledHrsDaily, totalScheduledHrsDailyError])

  const loader = (size = 48) => {
    return (
      <div style={styles.mainContainerPosition}>
        <div style={styles.loadingIconContainer}>
          <div>
            <CircularProgress size={size} />
          </div>
        </div>
      </div>
    )
  }

  const displayErrors = (errorObj) => {
    if (errorObj?.message && errorObj?.code && ERROR_CODES_SHOW_MESSAGE_TIMECARD.includes(errorObj?.code)) {
      return (
        <div style={styles.mainContainerPosition}>
          <p style={styles.errorMessage}>{errorObj.message}</p>
        </div>
      )
    } else {
      return (
        <div style={styles.mainContainerPosition}>
          <p style={styles.errorMessage}>
            {UNABLE_TO_LOAD_TIMECARDS}
            <span style={styles.errorCodeMessage}>
              {formatErrorCode(errorObj, ERROR_CODE_CANNOT_CONNECT_TO_SERVER_TIMECARD)}
            </span>
          </p>
        </div>
      )
    }
  }

  const displayInfoMessage = (message) => {
    return (
      <div style={styles.mainContainerPosition}>
        <p style={styles.infoMessage}>{message}</p>
      </div>
    )
  }

  const displayEmptyContent = () => {
    return <div style={styles.mainContainerPosition} />
  }

  const renderComponent = () => {
    if (timeCardDataDailyError) {
      return displayErrors(timeCardDataDailyError?.response?.data)
    } else if (timeCardDataDaily && Object.keys(timeCardDataDaily).length > 0) {
      if (timeCardDataDaily.timecards.length === 0) {
        return displayInfoMessage(NO_TIMECARD_MESSAGE)
      } else {
        return viewableData()
      }
    }
    return displayEmptyContent()
  }

  const handleDateChange = (date) => {
    dispatch(setTimecardDailySelectedDate(date))
    getTimecardDailyData(date)
  }

  const viewCalendar = () => {
    return (
      <WeeklyCalendar
        id="TimecardDaily"
        selectedDate={new Date(timeCardDailySelectedDate)}
        onChange={(date) => handleDateChange(date)}
        minDate={minDate}
        maxDate={maxDate}
        isTimecardDaily={true}
      />
    )
  }

  const viewableData = () => {
    let noPunchAndNoPayCodes = !(
      timeCardDataDaily.timecards[0].total_punch_segments === 0 &&
      (!timeCardDataDaily.timecards[0].pay_code || timeCardDataDaily.timecards[0].pay_code.length === 0)
    )

    return (
      <React.Fragment>
        <div style={styles.mainContainerPosition}>
          <Paper elevation={1} square sx={styles.mainScrollContainer}>
            <HoursSummaryDaily
              scheduledHrs={totalScheduledHrsDaily}
              totalScheduledHrsLoader={totalScheduledHrsLoader}
              punchedHrs={timeCardDataDaily.timecards[0].punched_hours}
              paycodeHrs={timeCardDataDaily.timecards[0].paycode_hours}
              totalHrs={timeCardDataDaily.timecards[0].total_hours}
              showHideEmptyDiv={noPunchAndNoPayCodes}
              missedPunch={missedPunch}
            />

            {timeCardDataDaily.timecards[0].total_punch_segments > 0 && (
              <PunchesDaily
                punchSegments={timeCardDataDaily.timecards[0].punch_segments}
                styles={styles}
                isPunchedIn={isPunchedIn}
              />
            )}

            {timeCardDataDaily.timecards[0].pay_code?.length > 0 && (
              <OtherDaily
                payCodes={timeCardDataDaily.timecards[0].pay_code}
                styles={styles}
                isPunchedIn={isPunchedIn}
              />
            )}
            <MyProtectedElement
              allowed={['punch_correction']}
              renderNotPunchedIn={() => <FixAPunchButtonSectionNotPunchedIn />}
              renderUnauthorized={() => <></>}
              enablePunchStatusPolling={true}
              fullScreenLoading={false}
            >
              {timeCardDataDaily?.date && <FixAPunchButtonSection timeCardDataDaily={timeCardDataDaily} />}
            </MyProtectedElement>
          </Paper>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <HeaderTitle title={PAGE_TITLE} />
      {viewCalendar()}
      {timeCardDataDailyIsFetching ? loader() : renderComponent()}
    </React.Fragment>
  )
}
export default TimecardDaily
